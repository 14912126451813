<template>
  <div class="application-components-index-wrapper">
    <div
      class="application-index-introduction"
    >
      <div class="application-index-introduction-title">{{ $t('applicationIntroductionTitle') }}</div>
      <div class="application-index-introduction-des">
        <p>{{ $t('applicationIntroductionDes[0]') }}</p>
      </div>
    </div>
    <div
      class="application-index-img-list"
    >
      <div
        v-for="(item, index) in computedList"
        :key="index"
        @click="$router.push(item.link)"
      >
        <el-image
          fit="cover"
          :src="item.imgage" 
        />
        <div class="describe"  v-html="$t(item.prop)" />
      </div>
    </div>
  </div>
</template>

<script>
import { configIndex } from '@/utils/index'
export default {
  name: 'applicationComponentsIndex',
  data: () => {
    return {
      computedList: configIndex.sectionFour
    }
  }
}
</script>

<style lang="scss" scoped>
.application-components-index-wrapper {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  .application-index-introduction {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    .application-index-introduction-title {
      color: rgba(34, 34, 34, 1);
      font-size: 42px;
      line-height: 54px;
      text-align: center;
      font-weight: bold;
    }
    .application-index-introduction-des {
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      line-height: 32px;
      margin: 50px 0;
      p {
        margin: 40px 0;
      }
    }
  }
  .application-index-img-list {
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 170px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &>div {
      cursor: pointer;
      height: 574px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: 15px;
      position: relative;
      .el-image {
        width: 100%;
        height: 100%;
        ::v-deep img {
          transform: scale(1.04);
          transition: transform 0.3s;
          &:hover {
            transform: scale(1);
            transition: transform 0.3s;
          }
        }
      }
      &:nth-child(4n + 1), &:nth-child(4n + 4) {
        width: 40.5%;
      }
      &:nth-child(4n + 2),  &:nth-child(4n + 3) {
        width: 58.3%;
      }
      .describe {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 257px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        justify-content: flex-end;
        color: rgba(255, 255, 255, 1);
        font-size: 38px;
        background-image: url('~@/assets/image/application/bj.png');
        background-size: 100% 100%;
        pointer-events: none;
        font-family: mySecondFont;
      }
    }
  }
}
</style>